<template>
  <div class="d-inline-flex flex-column align-center">
    <v-avatar color="primary" size="150" class="elevation-6">
      <img :alt="`Avatar of ${name}`" :src="image" />
    </v-avatar>
    <p class="mt-4 mb-0 font-weight-bold">
      {{ name }}
    </p>
    <span class="text--secondary mb-4">

      {{ functions }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'Person',

  props: {
    name: {
      type: String,
      required: true
    },
    functions: {
      type: String,
      required: true
    },
    image: {
      required: true
    }
  }
}
</script>
